import React, { useState, useEffect } from 'react';
import './TingsAndRoutes.css';

import { GoogleMap, InfoWindowF, LoadScript, MarkerF } from '@react-google-maps/api';

import { fetchNewTings } from '../../utils/firebase/getTings'
import moment from 'moment';

import { animalTextGenerator, locationText } from '../../utils/tingInfo/generateTingText';
import { pinImage } from '../../utils/map/generateMapPins';
import { fetchNewRoutePoints } from '../../utils/firebase/getUserRoutes';
import { getUser } from '../../utils/firebase/getUser';

import useUserProfileStore from '../../stores/userProfileStore';
import useAuthStore from '../../stores/authStore';

import logo from '../../assets/logo/Latest-Sightings-Logo-RGB-Horizontal.png';

import { sendEvent, sendPageView } from '../../utils/GA/googleAnalyticFunctions'
import { BeatLoader } from "react-spinners";


import { auth } from '../../firebase';

import Modal from "react-modal";

export const TingsAndRoutes = () => {

    const [selectedTing, setSelectedTing] = useState({});
    const [openTing, setOpenTing] = useState(false);
    const [tingsDB, setTingsDB] = useState([]);
    const [loadingTings, setLoadingTings] = useState(true);
    const [loadingRoutes, setLoadingRoutes] = useState(true);
    const [defaultCenter, setDefaultCenter] = useState({ lat: -25.4055, lng: 31.214 })
    const [keyChange, setKeyChange] = useState(false);

    const [routesDB, setRoutesDB] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState({});
    const [openRoute, setOpenRoute] = useState(false);

    // Add these two new state variables
    const [showTingPins, setShowTingPins] = useState(true);
    const [showRoutePins, setShowRoutePins] = useState(true);

    const [selectedTimeframe, setSelectedTimeframe] = useState("All");
    const [menuWrapper, setMenuWrapper] = useState(false);

    // Timeframe options for tings
    const [oneHr, setOneHr] = useState(true);
    const [fourHr, setFourHr] = useState(true);
    const [eightHr, setEightHr] = useState(true);
    const [twentyFourHr, setTwentyFourHr] = useState(true);

    const admin = useUserProfileStore(state => state.admin);
    const [username, setUsername] = useState("");
    const pageName = 'Admin View';

    const logout = useAuthStore(state => state.logout);
    const signIn = useAuthStore(state => state.signIn);

    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [buttonHovered, setButtonHovered] = useState(false);

    const [fetchingNewTings, setFetchingNewTings] = useState(false);
    const [fetchingNewTraffic, setFetchingNewTraffic] = useState(false);


    // const admin = false

    // const generateClusters = () => {
    //     const clusterCoordinates = httpsCallable(functions, 'clusterCoordinates');
    //     clusterCoordinates()
    // }

    useEffect(() => {
        sendPageView('Admin View');
    }, [])

    const filterRoutesByTimeframe = (item) => {
        if (selectedTimeframe === "All") {
            const startOfDay = moment().startOf('day');
            return moment.unix(item.timestamp?._seconds).isSameOrAfter(startOfDay);
        }
        const durationMapping = {
            "30 min": 0.5,
            "1hr": 1,
            "2 hr": 2,
            "4hr": 4,
        };
        const duration = durationMapping[selectedTimeframe];
        return moment.unix(item.timestamp?._seconds).isAfter(moment(new Date()).subtract(duration, 'hours'));
    };

    useEffect(() => {

        const dataTings = window.localStorage.getItem('tingsDB');
        handleGetTings(dataTings)

        if (admin) {
            const dataRoutes = window.localStorage.getItem('downloadedRoutes');
            handleGetRoutes(dataRoutes)
        }

        // clear the local storage
        // window.localStorage.clear();
        // window.localStorage.removeItem('tingsDB');
        // window.localStorage.removeItem('lastSnapshotTings');
        // window.localStorage.removeItem('downloadedRoutes');
    }, [keyChange]);

    const handleGetRoutes = async (data) => {
        setLoadingRoutes(true);
        // console.log('data', data);
        if (data !== null && data !== undefined && data !== "undefined") {
            console.log('Loading from storage');
            setRoutesDB(JSON.parse(data))
            setLoadingRoutes(false)
        }
        else {
            const routesData = await fetchNewRoutePoints()
            setRoutesDB(routesData.filtered);
            window.localStorage.setItem('downloadedRoutes', JSON.stringify(routesData.filtered));
            setLoadingRoutes(routesData.loaded)
        }
    }

    const handleGetTings = async (data) => {
        setLoadingTings(true);
        // console.log('data', JSON.parse(data));
        if (data !== null && data !== undefined && data !== "undefined") {
            console.log('Loading from storage');
            setTingsDB(JSON.parse(data))
            setLoadingTings(false)
        }
        else {
            console.log('Loading from firestore');
            const tingsData = await fetchNewTings()
            setTingsDB(tingsData.storedPosts);
            window.localStorage.setItem('tingsDB', JSON.stringify(tingsData.storedPosts));
            setLoadingTings(tingsData.loaded)
        }
    }
    const mapStyles = {
        height: "100vh",
        width: "100%"
    };

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => (document.body.style.overflow = "scroll");
    });

    // Show pin or not
    const filterPins = (pin) => {
        const hourDifference = (moment().diff(moment.unix((pin.tingTime?.seconds ?? pin.tingTime?._seconds)), 'hours'))
        if (hourDifference < 1 && oneHr) {
            return true
        } else if ((hourDifference < 4) && (hourDifference >= 1) && fourHr) {
            return true
        } else if (hourDifference < 8 && hourDifference >= 4 && eightHr) {
            return true
        } else if (hourDifference < 24 && hourDifference >= 8 && twentyFourHr) {
            return true
        } else {
            return false;
        }
    }

    // Get username from getUser function
    const getUsername = async (userId, selectedTing) => {
        const user = await getUser(userId);
        console.log('user.docData', user.userDocData);
        if (!selectedTing.hasOwnProperty("adminTingedForWho") || selectedTing.adminTingedForWho.length === 0) {
            return user.userDocData.username;
        } else {
            return selectedTing.adminTingedForWho;
        }
    }

    useEffect(() => {
        const fetchUsername = async () => {
            if (selectedTing && selectedTing.userId) {
                const fetchedUsername = await getUsername(selectedTing.userId, selectedTing);
                setUsername(fetchedUsername);
            }
        };

        fetchUsername();
    }, [selectedTing]);

    return (

        <>
            <div
                className="hamburger-icon"
                onClick={() => setMenuWrapper(!menuWrapper)}
            >
                <div></div>
                <div></div>
                <div></div>
            </div>


            <div
                className={`menu-wrapper ${menuWrapper ? "visible" : ""}`}
            >
                <button
                    className="modern-button"
                    onClick={async () => {
                        setFetchingNewTings(true);
                        const dataFetch = await fetchNewTings()
                        if (!dataFetch.loaded) {
                            setOpenTing(false);
                            setOpenRoute(false);
                            setFetchingNewTings(false);
                            setKeyChange(!keyChange)
                        }
                        console.log('pageName', pageName);
                        sendEvent(pageName, "user_engagement", "refresh_tings", "button_press");

                    }}>
                    {fetchingNewTings
                        ? <BeatLoader color={"orange"} loading={fetchingNewTings} size={20} />
                        : 'Refresh tings'
                    }
                </button>

                {/* Button to get routes */}
                {admin &&
                    <><button
                        className="modern-button"
                        onClick={async () => {
                            setFetchingNewTraffic(true);
                            const dataFetch = await fetchNewRoutePoints()
                            if (!dataFetch.loaded) {
                                setOpenTing(false);
                                setOpenRoute(false);
                                setFetchingNewTraffic(false);
                                setKeyChange(!keyChange)
                            }
                            sendEvent(pageName, "user_engagement", "refresh_traffic", "button_press");

                        }}>
                        {fetchingNewTraffic
                            ? <BeatLoader color={"orange"} loading={fetchingNewTraffic} size={20} />
                            : 'Refresh traffic'
                        }
                    </button>

                        <button className="modern-button"
                            onClick={() => {
                                window.localStorage.removeItem('lastSnapshotRoutes');
                                window.localStorage.removeItem('downloadedRoutes');
                                setKeyChange(!keyChange)
                                sendEvent(pageName, "user_engagement", "delete_traffic", "button_press");
                            }}>
                            Delete Traffic</button> </>}

                {/* <button style={{
                    padding: '10px',
                    backgroundColor: 'white',
                    border: '1px solid black',
                }}

                    onClick={() => {
                        generateClusters()

                    }}>
                    Generate Clusters</button> */}

                {/* Show a heading that says Loading Tings: spinner if loadingTings is true */}
                {loadingTings && <h1 style={{
                    padding: '10px',
                    backgroundColor: 'white',
                    border: '1px solid black',
                }}
                >Loading Tings</h1>}
                {/* Show a heading that says Loading Routes: spinner if loadingRoutes is true */}
                {loadingRoutes && admin && <h1 style={{
                    padding: '10px',
                    backgroundColor: 'white',
                    border: '1px solid black',
                }}>Loading Routes</h1>}

                {admin && <>
                    <button className="modern-button"

                        onClick={() => {
                            sendEvent(pageName, "user_engagement", `
                            ${showTingPins ? "hide" : "show"}_tings`, "button_press");
                            setShowTingPins(!showTingPins);
                        }}>
                        {showTingPins ? "Hide" : "Show"} Tings
                    </button>

                    <button className="modern-button"

                        onClick={() => {
                            sendEvent(pageName, "user_engagement", `
                            ${showRoutePins ? "hide" : "show"}_traffic`, "button_press");
                            setShowRoutePins(!showRoutePins);
                        }}>
                        {showRoutePins ? "Hide" : "Show"} Traffic
                    </button>

                    <div className="dropdown">
                        <button>
                            Timeframe: {selectedTimeframe}
                        </button>
                        <div className="dropdown-content">
                            <a onClick={() => setSelectedTimeframe("30 min")}>30 min</a>
                            <a onClick={() => { setSelectedTimeframe("1hr"); }}>1hr</a>
                            <a onClick={() => { setSelectedTimeframe("2 hr"); }}>2 hr</a>
                            <a onClick={() => { setSelectedTimeframe("4hr"); }}>4hr</a>
                            <a onClick={() => { setSelectedTimeframe("All"); }}>All</a>
                        </div>
                    </div>
                </>}
                <div className="time-filter">
                    {/* have a title that says "Time filter" */}
                    <h1 style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        marginBottom: ".5rem"
                    }}>Time filter</h1>
                    <label className='last-hour'>
                        <span className="color-box"></span>
                        <input
                            type="checkbox"
                            value="1"
                            onChange={(newValue) => {
                                setOneHr(newValue.target.checked); setSelectedTing({})
                                sendEvent(pageName, "user_engagement", "time_filter",
                                    `last_hour_${newValue.target.checked ? "_checked" : "_unchecked"}`);
                            }}
                            defaultChecked

                        />
                        Last hour
                    </label>
                    <label className="last-4-hours">
                        <span className="color-box"></span>
                        <input
                            type="checkbox"
                            value="4"
                            onChange={(newValue) => {
                                setFourHr(newValue.target.checked); setSelectedTing({});
                                sendEvent(pageName, "user_engagement", "time_filter",
                                    `last_4_hours_${newValue.target.checked ? "_checked" : "_unchecked"}`);
                            }
                            }
                            defaultChecked
                        />
                        Last 4 hours
                    </label>
                    <label className="last-8-hours">
                        <span className="color-box"></span>
                        <input
                            type="checkbox"
                            value="8"
                            onChange={(newValue) => {
                                setEightHr(newValue.target.checked); setSelectedTing({});
                                sendEvent(pageName, "user_engagement", "time_filter",
                                    `last_8_hours_${newValue.target.checked ? "_checked" : "_unchecked"}`);
                            }}
                            defaultChecked
                        />
                        Last 8 hours
                    </label>
                    <label className="last-24-hours">
                        <span className="color-box"></span>
                        <input
                            type="checkbox"
                            value="24"
                            onChange={(newValue) => {
                                setTwentyFourHr(newValue.target.checked); setSelectedTing({});
                                sendEvent(pageName, "user_engagement", "time_filter",
                                    `last_24_hours_${newValue.target.checked ? "_checked" : "_unchecked"}`);
                            }}
                            defaultChecked
                        />
                        Last 24 hours
                    </label>
                </div>
                {/* <a href="https://www.latestsightings.com/app?utm_source=LatestSightings&utm_medium=Sightings-Board" target="_blank" rel="noopener noreferrer"> */}
                <img src={logo} alt="Logo" className="logo" />
                {/* </a> */}

                {auth.currentUser ?
                    <button
                        className="modern-button bottom-right-button"
                        onClick={() => {
                            logout();
                            window.location.reload();
                        }
                        }
                    >
                        Logout
                    </button>
                    :
                    <button
                        className="modern-button bottom-right-button"
                        onClick={() => setLoginModalOpen(true)}
                    >
                        Login
                    </button>
                }
            </div>
            <Modal
                isOpen={loginModalOpen}
                onRequestClose={() => setLoginModalOpen(false)}
                contentLabel="Login Modal"
                className="login-modal"
            >
                {/* Add your login form content here */}
                <form
                    onSubmit={async (e) => {
                        const success = await signIn(e, email, password);
                        if (success) {
                            // refresh the page
                            window.location.reload();
                        }
                    }}
                >

                    <div className='auth__formDiv'>
                        <label className='auth__formHeading'>Email Address</label>
                        <input onChange={(e) => setEmail(e.target.value)} className='auth__formInput' type='email' />
                    </div>
                    <div className='auth__formDiv'>
                        <label className='auth__formHeading'>Password</label>
                        <input onChange={(e) => setPassword(e.target.value)} className='auth__formInput' type='password' />
                    </div>
                    <button
                        onMouseEnter={() => setButtonHovered(true)}
                        onMouseLeave={() => setButtonHovered(false)}
                        className={buttonHovered ? 'auth__buttonHovered' : 'auth__buttonNotHovered'}>
                        Login
                    </button>
                </form>
            </Modal>



            <LoadScript
                googleMapsApiKey='AIzaSyCEyKJNQPNEPqybZAwlzYJxKDrUqJ5RC-g'>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={7}
                    center={defaultCenter}
                    key={keyChange}
                    options={{ gestureHandling: 'greedy' }}
                >
                    {showTingPins && tingsDB &&
                        tingsDB.map(item => {




                            return (
                                (moment().diff(moment.unix((item.tingTime?.seconds ?? item.tingTime?._seconds)), 'hours') < 24) &&
                                filterPins(item) &&
                                <MarkerF
                                    key={item.id}
                                    position={{ lat: item.coordinates._latitude, lng: item.coordinates._longitude }}
                                    onClick={() => {
                                        setSelectedTing(item)
                                        setOpenTing(true)
                                        setDefaultCenter({ lat: item.coordinates._latitude, lng: item.coordinates._longitude })
                                        sendEvent(pageName, "user_engagement", "marker_click", `${item.animal}`);

                                    }}
                                    // custom icon
                                    icon={{
                                        url: (pinImage(item)),
                                    }}
                                >

                                    {openTing && selectedTing.id === item.id
                                        &&
                                        <InfoWindowF
                                            clickable={true}
                                            onCloseClick={() => {
                                                setOpenTing(!openTing)
                                            }}
                                        >
                                            <>
                                                <p> {(moment().diff(moment.unix((selectedTing.tingTime?.seconds ?? selectedTing.tingTime?._seconds)), 'days') < 2) ?
                                                    moment.unix((selectedTing.tingTime?.seconds ?? selectedTing.tingTime?._seconds)).calendar()
                                                    :
                                                    moment.unix((selectedTing.tingTime?.seconds ?? selectedTing.tingTime?._seconds)).format('MMMM Do YYYY, h:mm a')
                                                }</p>
                                                <p>{animalTextGenerator(selectedTing.number, selectedTing.animal, selectedTing.action, selectedTing.extraAnimal)}</p>
                                                <p>{locationText(selectedTing.roadname, selectedTing.locationDescription)}</p>
                                                <p>Tinged by: {username}</p>
                                                {/* Show image */}
                                                {selectedTing.postImg ? <img src={selectedTing.postImg} alt="ting" style={{ width: '60%', padding: 10 }} /> :
                                                    <p>(No photo)</p>
                                                }
                                            </>
                                        </InfoWindowF>
                                    }
                                </MarkerF>

                            )

                        })
                    }


                    {showRoutePins && routesDB && admin &&

                        // Write a .map that loops through routes and change the colour of hte pins depending on the userId

                        routesDB.map(item => {
                            // Only show pins in last 30 minutes
                            if (
                                filterRoutesByTimeframe(item)
                            ) {
                                return (
                                    <MarkerF
                                        key={item.id}
                                        position={{ lat: item.coordinates._latitude, lng: item.coordinates._longitude }}
                                        onClick={() => {
                                            setSelectedRoute(item)
                                            setOpenRoute(true)
                                            setDefaultCenter({ lat: item.coordinates._latitude, lng: item.coordinates._longitude })
                                        }}
                                        // Standard icon
                                        // icon='green'
                                        icon={require(`../../assets/mapPins/circle/blue-small_17.png`)}


                                    // options={{disableAutoPan: true}}
                                    >

                                        {openRoute && selectedRoute.id === item.id
                                            &&
                                            <InfoWindowF
                                                // position={selected.location}
                                                clickable={true}
                                                onCloseClick={() => {
                                                    setOpenRoute(!openRoute)
                                                }}
                                            >
                                                <>
                                                    <p>{selectedRoute.userId}</p>
                                                    <p>
                                                        {moment.unix(selectedRoute.timestamp._seconds).calendar()}
                                                    </p>
                                                </>
                                            </InfoWindowF>

                                        }
                                    </MarkerF>

                                )
                            }

                        })
                    }
                </GoogleMap>
            </LoadScript >
        </>
    )
}

export default TingsAndRoutes;