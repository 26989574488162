import { db } from '../../firebase';
import { collection, query, orderBy, getDoc, where, limit, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from 'moment';

const functions = getFunctions();

export const fetchNewRoutePoints = async () => {

    try {
        let lastSnapshot = window.localStorage.getItem('lastSnapshotRoutes');
        console.log('lastSnapshot', lastSnapshot);

        let storedPosts = new Array();

        let downloadedTings = window.localStorage.getItem('downloadedRoutes')
        console.log('downloadedTings', downloadedTings);

        if (downloadedTings !== null && downloadedTings !== undefined && downloadedTings !== "undefined") {
            storedPosts = JSON.parse(downloadedTings);
                    // If the timestamp is not from today, then remove it from the list
        }

        // Remove posts older than 1 day
        storedPosts = storedPosts.filter((post) => moment.unix(post.timestamp?._seconds).isAfter(moment(new Date()).subtract(1, 'days')))


        if (lastSnapshot && !moment(lastSnapshot).isAfter(moment(new Date()).subtract(1, 'days'))) {
            storedPosts = new Array();
        }


        const fetchNewUserRoutesFromFirestore = httpsCallable(functions, 'firestore-fetchNewUserRoutes');
        const data = await fetchNewUserRoutesFromFirestore({
            lastSnapshot:
                // Check if lastSnapshot is less than 1 day ol
                lastSnapshot && moment(lastSnapshot).isAfter(moment(new Date()).subtract(1, 'days')) ? lastSnapshot : null, storedPostsLength: storedPosts.length
        });
        let newPosts = data.data.newPosts;
        const finishedDownloadingTings = data.data.finishedDownloadingTings;
        const lastVisible = data.data.lastVisible;
        // console.log('newPosts', newPosts);
        console.log('newPosts.length', newPosts.length);


        if (newPosts.length > 0) {
            finishedDownloadingTings && window.localStorage.setItem('lastSnapshotRoutes', (moment(new Date()).subtract(1, 'minutes')).toISOString())

            if (newPosts.length === 0) {
                // console.log('lists', list);
                storedPosts = newPosts


            } else {
                // Update old post with new changes:
                newPosts.forEach((item) => {
                    const index = storedPosts.findIndex((post) => post.id === item.id);

                    if (index !== -1) {
                        if (item.deletedTime) {
                            storedPosts.splice(index, 1);
                        } else {
                            storedPosts[index] = item;
                        }
                    }
                    else {
                        console.log('not in list');
                        !item.deletedTime && storedPosts.unshift(item); // Add to the top of the list 
                    }
                });


            }
        }

        // Convert timestamps to dates with time and minute
        storedPosts.forEach((post) => {
            // Check if timestamp is a string
            if (post?.timestampString === undefined) {
                post.timestampString = moment.unix(post.timestamp?._seconds).format('YYYY-MM-DD HH:mm');
                console.log('post.timestamp', post.timestamp);
            }
            // console.log('post.timestamp',  moment.unix(post.timestamp?._seconds).format('YYYY-MM-DD HH:mm'));
        })

        const filteredPosts = storedPosts,
            keys = ['timestampString', 'userId'],
            filtered = filteredPosts.filter(
                (s => o =>
                    (k => !s.has(k) && s.add(k))
                        (keys.map(k => o[k]).join('|'))
                )
                    (new Set)
            );

        console.log("filteredPosts", filtered);

        // const filteredRoutes = storedPosts.filter((route, index, self) =>
        //     index === self.findIndex((t) => (
        //         t.userId === route.userId && moment(t.timestamp).isSame(route.timestamp, 'minute')
        //     ))
        // )

        console.log('Setting to local storage');
        // console.log('filteredPosts.length', filteredPosts);
        window.localStorage.setItem('downloadedRoutes', JSON.stringify(filtered))
        console.log('Added to local storage');
        console.log('filtered.length', filtered.length);

        const loaded = false

        return {
            filtered, lastVisible, loaded
        }

    } catch (e) {
        console.log(e);
    }
};