import React, { useEffect, useRef, useState } from 'react';
import './SightingsBoard.css';

import { GoogleMap, InfoWindowF, LoadScript, MarkerF } from '@react-google-maps/api';

import moment from 'moment';
import { fetchNewTings } from '../../utils/firebase/getTings';

import { getUser } from '../../utils/firebase/getUser';
import { pinImage } from '../../utils/map/generateMapPins';
import { animalTextGenerator, locationText } from '../../utils/tingInfo/generateTingText';

import useAuthStore from '../../stores/authStore';
import useUserProfileStore from '../../stores/userProfileStore';

import logo from '../../assets/logo/Latest-Sightings-Logo-RGB-Horizontal.png';
import { sendEvent, sendPageView } from '../../utils/GA/googleAnalyticFunctions';

import { faHandPointer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from "react-modal";

import { BeatLoader } from "react-spinners";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


export const SightingsBoard = () => {

    const [selectedTing, setSelectedTing] = useState({});
    const [openTing, setOpenTing] = useState(false);
    const [tingsDB, setTingsDB] = useState([]);
    const [loadingTings, setLoadingTings] = useState(true);
    const [defaultCenter, setDefaultCenter] = useState({ lat: -25.16360031658689, lng: 31.60367747583534 })
    const [zoom, setZoom] = useState(10);
    const [keyChange, setKeyChange] = useState(false);
    const [showTouchScreenOverlay, setShowTouchScreenOverlay] = useState(false);

    const signIn = useAuthStore(state => state.signIn);

    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [buttonHovered, setButtonHovered] = useState(false);

    const pageName = `Sightings Board${window.location.search.split('utm_source=')[1] ? `${" - "} ${window.location.search.split('utm_source=')[1].split('&')[0]}` : ''}`

    // Add these two new state variables
    const [showTingPins, setShowTingPins] = useState(true);
    const [oneHr, setOneHr] = useState(true);
    const [fourHr, setFourHr] = useState(true);
    const [eightHr, setEightHr] = useState(true);
    const [twentyFourHr, setTwentyFourHr] = useState(true);

    const [menuWrapper, setMenuWrapper] = useState(false);
    // Define new state for settings menu
    const [settingsOpen, setSettingsOpen] = useState(false);


    const admin = useUserProfileStore(state => state.admin);
    const [username, setUsername] = useState("");

    const [fetchingNewTings, setFetchingNewTings] = useState(false);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        vertical: true,
        verticalSwiping: true,
    };

    const idleTimer = useRef(null);

    useEffect(() => {
        // Start the idle timer when the component mounts
        startIdleTimer();

        return () => {
            // Clean up the idle timer when the component unmounts
            clearInterval(idleTimer.current);
        };
    }, []);

    const handleIdleTimeout = () => {
        // Handle idle timeout (1 minute of inactivity)
        // Refresh tings and recenter the map

        handleRefreshTings();
        setShowTouchScreenOverlay(true);

    };
    useEffect(() => {

        // Clear the idle timer and start a new one when user activity is detected
        const handleUserActivity = () => {
            clearTimeout(idleTimer.current);
            startIdleTimer();
            setShowTouchScreenOverlay(false);
        };

        // Set up event listeners for user activity
        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener('keydown', handleUserActivity);
        document.addEventListener('touchstart', handleTouchStart);

        return () => {
            // Clean up event listeners when the component unmounts
            document.removeEventListener('mousemove', handleUserActivity);
            document.removeEventListener('keydown', handleUserActivity);
            document.removeEventListener('touchstart', handleTouchStart);

        };
    }, []);

    const startIdleTimer = () => {
        // Start the idle timer
        idleTimer.current = setTimeout(() => {
            // Trigger idle timeout after 1 minute
            handleIdleTimeout();
        }, 60000); // 30 seconds

    };

    const handleUserActivity = () => {
        clearTimeout(idleTimer.current);
        startIdleTimer();
        setShowTouchScreenOverlay(false);
    };


    const handleTouchStart = () => {
        handleUserActivity();
    };

    const handleRefreshTings = async () => {
        setFetchingNewTings(true);
        const dataFetch = await fetchNewTings();
        console.log('dataFetch');
        if (!dataFetch.loaded) {
            setOpenTing(false);
            setFetchingNewTings(false);
            setKeyChange(!keyChange);
            setDefaultCenter({ lat: -25.16360031658689, lng: 31.60367747583534 });
            setZoom(10);
        }
    };

    // const admin = false

    useEffect(() => {
        sendPageView(pageName);
    }, [])

    useEffect(() => {

        const dataTings = window.localStorage.getItem('tingsDB');
        handleGetTings(dataTings)

    }, [keyChange]);

    const handleGetTings = async (data) => {
        setLoadingTings(true);
        // console.log('data', JSON.parse(data));
        if (data !== null && data !== undefined && data !== "undefined") {
            console.log('Loading from storage');
            setTingsDB(JSON.parse(data))
            setLoadingTings(false)
        }
        else {
            console.log('Loading from firestore');
            const tingsData = await fetchNewTings()
            setTingsDB(tingsData.storedPosts);
            window.localStorage.setItem('tingsDB', JSON.stringify(tingsData.storedPosts));
            setLoadingTings(tingsData.loaded)
        }
    }
    const mapStyles = {
        height: "100vh",
        width: "100%"
    };

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => (document.body.style.overflow = "scroll");
    });

    // Show pin or not
    const filterPins = (pin) => {
        const hourDifference = (moment().diff(moment.unix((pin.tingTime?.seconds ?? pin.tingTime?._seconds)), 'hours'))
        if (hourDifference < 1 && oneHr) {
            return true
        } else if ((hourDifference < 4) && (hourDifference >= 1) && fourHr) {
            return true
        } else if (hourDifference < 8 && hourDifference >= 4 && eightHr) {
            return true
        } else if (hourDifference < 24 && hourDifference >= 8 && twentyFourHr) {
            return true
        } else {
            return false;
        }
    }

    useEffect(() => {
        // Get all pins that should be displayed based on the current filters
        const displayedPins = tingsDB.filter(filterPins);

        // If no pins to be displayed, show alert and reset filters
        if (displayedPins.length === 0 && loadingTings === false) {
            setOneHr(true);
            setFourHr(true);
            setEightHr(true);
            setTwentyFourHr(true);
        }
    }, [oneHr, fourHr, eightHr, twentyFourHr]);

    // Get username from getUser function
    const getUsername = async (userId, selectedTing) => {
        const user = await getUser(userId);
        console.log('user.docData', user.userDocData);
        if (!selectedTing.hasOwnProperty("adminTingedForWho") || selectedTing.adminTingedForWho.length === 0) {
            return user.userDocData.username;
        } else {
            return selectedTing.adminTingedForWho;
        }
    }

    useEffect(() => {
        const fetchUsername = async () => {
            if (selectedTing && selectedTing.userId) {
                const fetchedUsername = await getUsername(selectedTing.userId, selectedTing);
                setUsername(fetchedUsername);
            }
        };

        fetchUsername();
    }, [selectedTing]);

    const refreshTings = async () => {
        setFetchingNewTings(true);
        const dataFetch = await fetchNewTings()
        if (!dataFetch.loaded) {
            setOpenTing(false);
            setFetchingNewTings(false);
            setKeyChange(!keyChange)
        }
    }

    useEffect(() => {
        refreshTings()
    }, [])

    return (

        <>
            <div
                className="hamburger-icon"
                onClick={() => setMenuWrapper(!menuWrapper)}
            >
                <div></div>
                <div></div>
                <div></div>
            </div>

            {showTouchScreenOverlay && (
                <div className="touch-screen-overlay">
                    <h2><FontAwesomeIcon className="flicker-icon" icon={faHandPointer} /></h2>
                    <h2> Touch the screen!</h2>
                </div>
            )}


            <div
                className={`menu-wrapper ${menuWrapper ? "visible" : ""}`}
            >
                <button
                    className="modern-button"
                    onClick={async () => {
                        setFetchingNewTings(true);
                        const dataFetch = await fetchNewTings()
                        if (!dataFetch.loaded) {
                            setOpenTing(false);
                            setFetchingNewTings(false);
                            setKeyChange(!keyChange)
                        }
                        console.log('pageName', pageName);
                        sendEvent(pageName, "user_engagement", "refresh_tings", "button_press");
                    }}>
                    {
                        fetchingNewTings ? <BeatLoader color={"orange"} loading={fetchingNewTings} size={20} />
                            : "Refresh tings"
                    }
                </button>
                <button
                    className="modern-button"
                    onClick={async () => {
                        // Recenter map
                        setDefaultCenter({ lat: -25.16360031658689, lng: 31.60367747583534 })
                        sendEvent(pageName, "user_engagement", "recenter_map", "button_press");

                    }}>
                    Recenter map</button>
                <div className="time-filter">
                    <h1 style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        marginBottom: ".5rem"
                    }}>Times</h1>
                    <label className='last-hour'>
                        <span className="color-box"></span>
                        {/* <input
                            type="checkbox"
                            value="1"
                            onChange={(newValue) => {
                                setOneHr(newValue.target.checked); setSelectedTing({})
                                sendEvent(pageName, "user_engagement", "time_filter",
                                    `last_hour_${newValue.target.checked ? "_checked" : "_unchecked"}`);
                            }}
                            defaultChecked
                            checked={oneHr}

                        /> */}
                        Last hour
                    </label>
                    <label className="last-4-hours">
                        <span className="color-box"></span>
                        {/* <input
                            type="checkbox"
                            value="4"
                            onChange={(newValue) => {
                                setFourHr(newValue.target.checked); setSelectedTing({});
                                sendEvent(pageName, "user_engagement", "time_filter",
                                    `last_4_hours_${newValue.target.checked ? "_checked" : "_unchecked"}`);
                            }
                            }
                            defaultChecked
                            checked={fourHr}
                        /> */}
                        Last 4 hours
                    </label>
                    <label className="last-8-hours">
                        <span className="color-box"></span>
                        {/* <input
                            type="checkbox"
                            value="8"
                            onChange={(newValue) => {
                                setEightHr(newValue.target.checked); setSelectedTing({});
                                sendEvent(pageName, "user_engagement", "time_filter",
                                    `last_8_hours_${newValue.target.checked ? "_checked" : "_unchecked"}`);
                            }}
                            defaultChecked
                            checked={eightHr}
                        /> */}
                        Last 8 hours
                    </label>
                    <label className="last-24-hours">
                        <span className="color-box"></span>
                        {/* <input
                            type="checkbox"
                            value="24"
                            onChange={(newValue) => {
                                setTwentyFourHr(newValue.target.checked); setSelectedTing({});
                                sendEvent(pageName, "user_engagement", "time_filter",
                                    `last_24_hours_${newValue.target.checked ? "_checked" : "_unchecked"}`);
                            }}
                            defaultChecked
                            checked={twentyFourHr}
                        /> */}
                        Last 24 hours
                    </label>
                </div>
                {/* If the utm sourche is millys or kruger shalati, please make hte button not clicable */}
                {/* <a href="https://www.latestsightings.com/app?utm_source=LatestSightings&utm_medium=Sightings-Board" target="_blank" rel="noopener noreferrer"> */}
                <img src={logo} alt="Logo" className="logo" />
                {/* </a> */}
               
                {/* Settings button */}
                <button
                    className="modern-button bottom-right-button"
                    onClick={() => setSettingsOpen(!settingsOpen)}
                >
                    Admin
                </button>

                {settingsOpen && (
                    <div className="settings-menu">
                        {/* Delete cache button */}
                        <button
                            className="modern-button"
                            onClick={() => {
                                window.localStorage.removeItem('tingsDB');
                                window.localStorage.removeItem('lastSnapshotTings');
                                window.location.reload();
                            }}
                        >
                            Delete cache
                        </button>
                    </div>
                )}
                <div className="carousel-overlay">
                    <Slider {...settings}>
                        {tingsDB
                            .filter(ting => {
                                const tingTime = moment.unix(ting.tingTime?.seconds ?? ting.tingTime?._seconds);
                                const now = moment();
                                return now.diff(tingTime, 'hours') <= 24;
                            }).map((ting, index) => (
                                (ting.postImg) &&
                                <div key={index} className="carousel-item"
                                    onClick={() => {
                                        setSelectedTing(ting);
                                        setOpenTing(true);
                                        setDefaultCenter({ lat: ting.coordinates._latitude, lng: ting.coordinates._longitude });
                                        sendEvent(pageName, "user_engagement", "carousel_item_click", `${ting.animal}`);
                                    }}
                                >
                                    <div className="carousel-info">
                                        <p> {(moment().diff(moment.unix((ting.tingTime?.seconds ?? ting.tingTime?._seconds)), 'days') < 2) ?
                                            moment.unix((ting.tingTime?.seconds ?? ting.tingTime?._seconds)).calendar()
                                            :
                                            moment.unix((ting.tingTime?.seconds ?? ting.tingTime?._seconds)).format('MMMM Do YYYY, h:mm a')
                                        }</p>
                                        <p>{ting.animal}</p>
                                        <h3>{ting.roadname}</h3>
                                    </div>
                                    {ting.postImg && <img src={ting.postImg} alt="Ting" />}
                                </div>
                            ))}
                    </Slider>
                </div>

            </div>
            <Modal
                isOpen={loginModalOpen}
                onRequestClose={() => setLoginModalOpen(false)}
                contentLabel="Login Modal"
                className="login-modal"
            >
                {/* Add your login form content here */}
                <form
                    onSubmit={async (e) => {
                        const success = await signIn(e, email, password);
                        if (success) {
                            // refresh the page
                            window.location.reload();
                        }
                    }}
                >

                    <div className='auth__formDiv'>
                        <label className='auth__formHeading'>Email Address</label>
                        <input onChange={(e) => setEmail(e.target.value)} className='auth__formInput' type='email' />
                    </div>
                    <div className='auth__formDiv'>
                        <label className='auth__formHeading'>Password</label>
                        <input onChange={(e) => setPassword(e.target.value)} className='auth__formInput' type='password' />
                    </div>
                    <button
                        onMouseEnter={() => setButtonHovered(true)}
                        onMouseLeave={() => setButtonHovered(false)}
                        className={buttonHovered ? 'auth__buttonHovered' : 'auth__buttonNotHovered'}>
                        Login
                    </button>
                </form>


            </Modal>


            <LoadScript
                googleMapsApiKey='AIzaSyCEyKJNQPNEPqybZAwlzYJxKDrUqJ5RC-g'>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={zoom}
                    center={defaultCenter}
                    key={keyChange}
                    options={{ gestureHandling: 'greedy' }}
                >
                    {showTingPins && tingsDB &&
                        tingsDB.map(item => {




                            return (
                                (moment().diff(moment.unix((item.tingTime?.seconds ?? item.tingTime?._seconds)), 'hours') < 24) &&
                                filterPins(item) &&
                                <MarkerF
                                    key={item.id}
                                    position={{ lat: item.coordinates._latitude, lng: item.coordinates._longitude }}
                                    onClick={() => {
                                        setSelectedTing(item)
                                        setOpenTing(true)
                                        setDefaultCenter({ lat: item.coordinates._latitude, lng: item.coordinates._longitude })
                                        sendEvent(pageName, "user_engagement", "marker_click", `${item.animal}`);
                                    }}
                                    // custom icon
                                    icon={{
                                        url: (pinImage(item)),
                                    }}
                                >

                                    {openTing && selectedTing.id === item.id
                                        &&
                                        <InfoWindowF
                                            clickable={true}
                                            onCloseClick={() => {
                                                setOpenTing(!openTing)
                                            }}
                                        >
                                            <>
                                                <p> {(moment().diff(moment.unix((selectedTing.tingTime?.seconds ?? selectedTing.tingTime?._seconds)), 'days') < 2) ?
                                                    moment.unix((selectedTing.tingTime?.seconds ?? selectedTing.tingTime?._seconds)).calendar()
                                                    :
                                                    moment.unix((selectedTing.tingTime?.seconds ?? selectedTing.tingTime?._seconds)).format('MMMM Do YYYY, h:mm a')
                                                }</p>
                                                <p>{animalTextGenerator(selectedTing.number, selectedTing.animal, selectedTing.action, selectedTing.extraAnimal)}</p>
                                                <p>{locationText(selectedTing.roadname, selectedTing.locationDescription)}</p>
                                                <p>Tinged by: {selectedTing.adminTingedForWho ?? selectedTing.userData.username}</p>

                                                {/* Show image */}
                                                {selectedTing.postImg ? <img src={selectedTing.postImg} alt="ting" style={{ width: '60%', padding: 10 }} /> :
                                                    <p>(No photo)</p>
                                                }
                                            </>
                                        </InfoWindowF>
                                    }
                                </MarkerF>

                            )

                        })
                    }
                </GoogleMap>
            </LoadScript >

        </>
    )
}

export default SightingsBoard;