import ReactGA from 'react-ga4';

export const sendPageView = (pageName) => {
    ReactGA.send({
        hitType: "pageview", 
        title: pageName, 
        // I want page to be without any query params but with the pathname before "?"
        page: window.location?.pathname + window.location?.search.split('?')[0],
        utm_source: window.location.search.split('utm_source=')[1] ? window.location.search.split('utm_source=')[1].split('&')[0] : '',
        utm_medium: window.location.search.split('utm_medium=')[1] ? window.location.search.split('utm_medium=')[1].split('&')[0] : '',
        utm_campaign: window.location.search.split('utm_campaign=')[1] ? window.location.search.split('utm_campaign=')[1].split('&')[0] : '',
    });
}

export const sendEvent = (pageName, category, action, label) => {
    ReactGA.event({
        title: pageName, 
        category,
        action,
        label, 
        utm_source: window.location.search.split('utm_source=')[1] ? window.location.search.split('utm_source=')[1].split('&')[0] : '',
        utm_medium: window.location.search.split('utm_medium=')[1] ? window.location.search.split('utm_medium=')[1].split('&')[0] : '',
        utm_campaign: window.location.search.split('utm_campaign=')[1] ? window.location.search.split('utm_campaign=')[1].split('&')[0] : '',
    });
}
