import React from "react";

// AuthStack
import Login from "./screens/authStack/Login";
import Register from "./screens/authStack/Register";

// AppStack
import TingsAndRoutes from "./screens/appStack/TingsAndRoutes";
import SightingsBoard from "./screens/appStack/SightingsBoard";
import SightingsSlider from "./screens/appStack/Widget/SightingsSlider";

import { Route, Routes } from "react-router-dom";
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import useAuthStore from "./stores/authStore";
import ProtectedRoute from "./navigation/ProtectedRoutes";
import UnprotectedRoute from "./navigation/UnprotectedRoutes";

import './App.css'
import SiteHeader from "./components/Header/MenuComponent";

import ReactGA from "react-ga4";



function App() {

  ReactGA.initialize('G-QW5XZTT9WX');
  // ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Custom Title" });

  // ReactGA.pageview(window.location.pathname + window.location.search);

  // const user = useAuthStore(state => state.user);

  return (
    <div className="app" >

      {/* <SiteHeader mode={'light'} /> */}

      <Routes>
        {/* AUTHstack */}
        <Route path="/login" element={<UnprotectedRoute><Login /></UnprotectedRoute>} />
        <Route path="/register" element={<UnprotectedRoute><Register /></UnprotectedRoute>} />

        {/* APPstack */}
        {/* Home page */}
        <Route path="/" element={
          <ProtectedRoute><TingsAndRoutes /></ProtectedRoute>} />
        <Route path="/sightings-board" element={
          <SightingsBoard />} />
        <Route path="/widget" element={
          <SightingsSlider />} />
      </Routes>
    </div>
  );
}

export default App;
