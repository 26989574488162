import moment from 'moment';
import { mapPins } from '../../data/mapPins';
import { fullAnimalList } from '../../data/animalList';

const checkAnimal = (location) => {
    // Find category in fullAnimalList that has the same name as the location.animal
    // Get animal category
    const animal = fullAnimalList.find(animal => animal.label === location.animal);

    // if lion 
    if (location.animal === 'Lion') {
        return 'Lion';
    }
    if (location.animal === 'Leopard') {
        return 'Leopard';
    }
    if (location.animal === 'Wild Dog') {
        return 'Wild Dog';
    }
    if (location.animal === 'Hyena' || location.animal === 'Spotted Hyena' || location.animal === 'Brown Hyena' || location.animal === 'Striped Hyena') {
        return 'Hyena';
    }
    if (location.animal === 'Elephant') {
        return 'Elephant';
    }
    if (location.animal === 'Cheetah') {
        return 'Cheetah';
    }
    if (location.animal === 'Buffalo') {
        return 'Buffalo';
    }
    if (location.animal === 'Giraffe') {
        return 'Giraffe';
    }
    if (location.animal === 'Hippopotamus') {
        return 'Hippopotamus';
    }
    if (location.animal === "Burchell's Zebra") {
        return "Burchell's Zebra";
    } if (animal !== undefined) {
        // console.log('animal label: ', animal.label);

        // console.log('animal category: ', animal.category);
        if (animal.category === 'BirdsOfPrey') {
            return "BirdsOfPrey";
        }
        if (animal.category === 'NonBirdsOfPrey') {
            return "NonBirdsOfPrey";
        }
        if (animal.category === 'Reptile') {
            return "Reptile";
        }
        if (animal.category === 'Scenery') {
            return "Scenery";
        }
        return 'plain'
    }
    else {
        return 'plain';
    }
}


export const pinImage = (location) => {
    const animalToLoad = checkAnimal(location);
    const hourDifference = (moment().diff(moment.unix((location.tingTime?.seconds ?? location.tingTime?._seconds)), 'hours'))

    if (hourDifference < 1) {
        return mapPins.find(pin => pin.id === animalToLoad).one_hr;
    } else if (hourDifference < 4 && hourDifference >= 1) {
        return mapPins.find(pin => pin.id === animalToLoad).four_hr;
    } else if (hourDifference < 8 && hourDifference >= 4) {
        return mapPins.find(pin => pin.id === animalToLoad).eight_hr;
    } else if (hourDifference < 24 && hourDifference >= 8) {
        return mapPins.find(pin => pin.id === animalToLoad).tf_hr;
    } else {
        return mapPins.find(pin => pin.id === animalToLoad).tf_hr;
    }

}