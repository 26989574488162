// Import the functions you need from the SDKs you need
import { TempleBuddhist } from "@mui/icons-material";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// import { getFunctions, httpsCallable } from "firebase/functions";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// TEMP
// const firebaseConfig = {
//     apiKey: "AIzaSyCS57mHPnjfbgab06gtO90g29kKMYGzu6Q",
//     authDomain: "latestsightingsadminpanel.firebaseapp.com",
//     projectId: "latestsightingsadminpanel",
//     storageBucket: "latestsightingsadminpanel.appspot.com",
//     messagingSenderId: "1062413706142",
//     appId: "1:1062413706142:web:4c4c34d5a8f9824b06ac8f",
//     measurementId: "G-PV9BMQBSXS"
// };

// MAIN
const firebaseConfig = {
  apiKey: "AIzaSyA4yLbj36DnQnF_n49MSwYSIhno_ad_ic4",
  authDomain: "latest-sightings-nadav-tuts.firebaseapp.com",
  projectId: "latest-sightings-nadav-tuts",
  storageBucket: "latest-sightings-nadav-tuts.appspot.com",
  messagingSenderId: "396934465771",
  appId: "1:396934465771:web:e5c49c9ca0f76eaf6156ae",
  measurementId: "G-VG9BQRL7HS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);


export default app



