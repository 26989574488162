import React, { useEffect, useState } from 'react';
import './SightingsSlider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

// Firebase imports
import { getFunctions, httpsCallable } from 'firebase/functions';

const SightingsSlider = () => {
  const [sightings, setSightings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSightings = async () => {
      try {
        const functions = getFunctions();
        const fetchSightingsCallable = httpsCallable(functions, 'firestore-fetchWidgetSightings');

        const result = await fetchSightingsCallable();
        const { sightingsData } = result.data;
        setSightings(sightingsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sightings:', error);
        setLoading(false);
      }
    };

    fetchSightings();
  }, []);

  const renderPlaceholder = () => {
    const placeholders = Array.from({ length: 10 });
    return placeholders.map((_, index) => (
      <SwiperSlide key={index}>
        <div className="sighting-card placeholder-card">
          <div className="sighting-info">
            <h5 className="placeholder-text">
              <strong>Loading tings...</strong>
            </h5>
            <div className="sighting-image placeholder-image" />
            <p className="tinged-by">Tinged by Loading...</p>
          </div>
        </div>
      </SwiperSlide>
    ));
  };

  const getTimeText = (sightingDate) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isToday =
      sightingDate.getDate() === today.getDate() &&
      sightingDate.getMonth() === today.getMonth() &&
      sightingDate.getFullYear() === today.getFullYear();

    const isYesterday =
      sightingDate.getDate() === yesterday.getDate() &&
      sightingDate.getMonth() === yesterday.getMonth() &&
      sightingDate.getFullYear() === yesterday.getFullYear();

    if (isToday) return 'today';
    if (isYesterday) return 'yesterday';
    return sightingDate.toLocaleDateString(); // Format as a date for older sightings
  };

  const getSlidesPerView = (defaultSlidesPerView) => {
    return sightings.length < defaultSlidesPerView ? sightings.length : defaultSlidesPerView;
  };

  if (loading) {
    return (
      <div className="sightings-slider">
        <Swiper
          spaceBetween={8}
          loop={true}
          autoplay={{
            delay: 3000,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
            },
            1280: {
              slidesPerView: 7,
            },
            1440: {
              slidesPerView: 8,
            },
          }}
        >
          {renderPlaceholder()}
        </Swiper>
      </div>
    );
  }

  if (sightings.length === 0) {
    return <p>No sightings available.</p>;
  }

  return (
    <div className={`sightings-slider ${sightings.length < 8 ? 'centered' : ''}`}>
      <Swiper
        spaceBetween={8}
        loop={sightings.length >= 8}
        autoplay={{
          delay: 3000,
        }}
        pagination={{
          clickable: true,
        }}
        centeredSlides={sightings.length < 8}
        navigation={true}
        modules={[Navigation]}
        breakpoints={{
          320: {
            slidesPerView: getSlidesPerView(1),
          },
          480: {
            slidesPerView: getSlidesPerView(2),
          },
          768: {
            slidesPerView: getSlidesPerView(3),
          },
          1024: {
            slidesPerView: getSlidesPerView(5),
          },
          1280: {
            slidesPerView: getSlidesPerView(7),
          },
          1440: {
            slidesPerView: getSlidesPerView(8),
          },
        }}
      >
        {sightings.map((sighting, index) => {
          const sightingDate = new Date(
            (sighting.tingTime.seconds ?? sighting.tingTime._seconds) * 1000
          );
          const timeText = getTimeText(sightingDate);

          const parkWords = sighting.park ? sighting.park.split(' ') : [];
          const firstParkWord = parkWords[0] || '';
          const restOfParkName = parkWords.slice(1).join(' ');

          return (
            <SwiperSlide key={index}>
              <a
                href="https://latestsightings.com/app?utm_source=latestsightings&utm_medium=sightings-widget"
                target="_blank"
                rel="noopener noreferrer"
                className="sighting-card-link"
              >
                <div className="sighting-card">
                  <div className="sighting-info">
                    <h5>
                      <strong>{sighting.animal}</strong> {timeText} in{' '}
                      <strong>{firstParkWord}</strong>
                    </h5>
                    <div className="sighting-image">
                      {sighting.postImg && (
                        <img src={sighting.postImg} alt={sighting.animal} />
                      )}
                    </div>
                    <p className="tinged-by">
                      Tinged by {sighting.adminTingedForWho ?? sighting.userData.username}
                    </p>
                  </div>
                </div>
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default SightingsSlider;
