export const mapPins = [
    {
        id: 'Lion',
        one_hr: require(`../assets/mapPins/lion/1hr_android.png`),
        four_hr: require(`../assets/mapPins/lion/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/lion/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/lion/24hr_android.png`)
    },
    {
        id: 'plain',
        one_hr: require(`../assets/mapPins/plain/1hr_android.png`),
        four_hr: require(`../assets/mapPins/plain/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/plain/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/plain/24hr_android.png`)
    },
    {
        id: 'Leopard',
        one_hr: require(`../assets/mapPins/leopard/1hr_android.png`),
        four_hr: require(`../assets/mapPins/leopard/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/leopard/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/leopard/24hr_android.png`)
    },
    {
        id: 'Elephant',
        one_hr: require(`../assets/mapPins/elephant/1hr_android.png`),
        four_hr: require(`../assets/mapPins/elephant/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/elephant/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/elephant/24hr_android.png`)
    },
    {
        id: 'Cheetah',
        one_hr: require(`../assets/mapPins/cheetah/1hr_android.png`),
        four_hr: require(`../assets/mapPins/cheetah/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/cheetah/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/cheetah/24hr_android.png`)
    },
    {
        id: 'Buffalo',
        one_hr: require(`../assets/mapPins/buffalo/1hr_android.png`),
        four_hr: require(`../assets/mapPins/buffalo/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/buffalo/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/buffalo/24hr_android.png`)
    },
    {
        id: 'Giraffe',
        one_hr: require(`../assets/mapPins/giraffe/1hr_android.png`),
        four_hr: require(`../assets/mapPins/giraffe/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/giraffe/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/giraffe/24hr_android.png`)
    },
    {
        id: 'Hippopotamus',
        one_hr: require(`../assets/mapPins/hippopotamus/1hr_android.png`),
        four_hr: require(`../assets/mapPins/hippopotamus/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/hippopotamus/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/hippopotamus/24hr_android.png`)
    },
    {
        id: "Burchell's Zebra",
        one_hr: require(`../assets/mapPins/zebra/1hr_android.png`),
        four_hr: require(`../assets/mapPins/zebra/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/zebra/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/zebra/24hr_android.png`)
    },
    {
        id: "Crocodile",
        one_hr: require(`../assets/mapPins/crocodile/1hr_android.png`),
        four_hr: require(`../assets/mapPins/crocodile/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/crocodile/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/crocodile/24hr_android.png`)
    },
    {
        id: "Wild Dog",
        one_hr: require(`../assets/mapPins/wildDog/1hr_android.png`),
        four_hr: require(`../assets/mapPins/wildDog/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/wildDog/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/wildDog/24hr_android.png`)
    },
    {
        id: "Hyena",
        one_hr: require(`../assets/mapPins/hyena/1hr_android.png`),
        four_hr: require(`../assets/mapPins/hyena/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/hyena/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/hyena/24hr_android.png`)
    },
    {
        id: "NonBirdsOfPrey",
        one_hr: require(`../assets/mapPins/nonBirdOfPrey/1hr_android.png`),
        four_hr: require(`../assets/mapPins/nonBirdOfPrey/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/nonBirdOfPrey/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/nonBirdOfPrey/24hr_android.png`)
    },

    {
        id: "BirdsOfPrey",
        one_hr: require(`../assets/mapPins/raptor/1hr_android.png`),
        four_hr: require(`../assets/mapPins/raptor/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/raptor/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/raptor/24hr_android.png`)
    },
    {
        id: "Reptile",
        one_hr: require(`../assets/mapPins/reptile/1hr_android.png`),
        four_hr: require(`../assets/mapPins/reptile/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/reptile/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/reptile/24hr_android.png`)
    },
    {
        id: "Scenery",
        one_hr: require(`../assets/mapPins/scenery/1hr_android.png`),
        four_hr: require(`../assets/mapPins/scenery/4hr_android.png`),
        eight_hr: require(`../assets/mapPins/scenery/8hr_android.png`),
        tf_hr: require(`../assets/mapPins/scenery/24hr_android.png`)
    },
]