// write Out Animal with action
import { fullAnimalList } from '../../data/animalList';

export const animalTextGenerator = (number, animal, action, extraAnimal) => {

    let animalText = ''
    if (number) {
        animalText = animalText + number
    }
    if (number && animal) {
        // get plural of animals 
        if (typeof number === 'string') {
            number = parseInt(number)
        }
        if (number > 1) {
            // find plural of animal from fullAnimalList
            try {
                let animalFinal = fullAnimalList.find(animaltoFind => animaltoFind.label === animal)
                animalText = animalText + ' ' + animalFinal.plural
            }
            catch (error) { animalText = animalText + ' ' + animal }
        } else {
            animalText = animalText + ' ' + animal
        }
    } else if (animal) {
        animalText = animalText + animal
    }
    if (action) {
        animalText = animalText + ' ' + action
    }
    if (extraAnimal) {
        animalText = animalText + ' & ' + extraAnimal
    }
    return animalText

}

// write Out Location
export const locationText = (roadname, locationDescription) => {
    let LocationText = ''
    // {roadname}{', '}{locationDescription}
    if (roadname && locationDescription) {
        LocationText = LocationText + roadname + '. '
    } else if (roadname) {
        LocationText = LocationText + roadname
    }
    if (locationDescription) {
        LocationText = LocationText + locationDescription
    } return LocationText

}

